import axios from 'axios';

export const getAssureClients = async (token: string) => {
  const {data} = await axios.get(`/assure/api/clients`, {
    headers: {
      Accept: 'application/json',
      Authorization: `Bearer ${token}`,
    },
  });
  return data;
};
