import React from 'react';
import PageLayout from '../../layouts/page-layout/PageLayout';
import {CustomError} from '../../ui-components';
import {useTranslation} from 'react-i18next';

const ServerErrorView: React.FC = () => {
  const {t} = useTranslation();

  return (
    <PageLayout>
      <CustomError
        title={t('Global.ServerError.Title')}
        message={t('Global.ServerError.Message')}
        errorVariant="generic"
      />
    </PageLayout>
  );
};

export default ServerErrorView;
