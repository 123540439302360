import {call, put, all, takeEvery, select} from 'redux-saga/effects';
import {RootState} from '../../store';
import {PayloadAction} from '@reduxjs/toolkit';
import {acquireAuthResult, msalInstance} from '../..';
import {errorHandlerAction, pageErrorHandlerAction} from '../../actions';
import {
  FundPortfolio,
  PortfolioPerformanceWidgetProps,
} from '../../types/index';
import {
  getFundDetailsPortfolio,
  getInvestmentPerformanceData,
} from '../../api/getFundDetailsPortfolio';
import {
  setFundPortfolioData,
  clearFundPortfolioData,
  getInvestmentPerformanceDataAction,
  setInvestmentPerformanceData,
} from '../../reducers/fundDetailsPortfolioSlice';

export const fundDetailsPortfolioSaga = function* fundDetailsPortfolioSaga({
  payload,
}: any): any {
  const id: string = payload?.match?.params?.id;
  if (id) {
    const isUserProfileUpdatePending = yield select(
      (state: RootState) => state.userProfile.isLoading
    );
    if (!isUserProfileUpdatePending) {
      yield put(clearFundPortfolioData());
    }
    try {
      const {accessToken} = yield call(acquireAuthResult, msalInstance);
      const response: FundPortfolio = yield call(
        getFundDetailsPortfolio,
        accessToken,
        id
      );
      yield put(setFundPortfolioData(response));
    } catch (err) {
      console.warn(err);
      yield put(pageErrorHandlerAction({error: err}));
    }
  }
};

export const updateFundPortfolioInvestmentsSaga =
  function* updateFundPortfolioInvestmentsSaga({
    payload,
  }: PayloadAction<{
    companyId: string;
    companyType: 'active' | 'exited';
    isFundCurrency: boolean;
    investmentGroupId: string;
    currency: string;
  }>) {
    try {
      const {accessToken} = yield call(acquireAuthResult, msalInstance);
      const response: PortfolioPerformanceWidgetProps = yield call(
        getInvestmentPerformanceData,
        accessToken,
        payload.companyId,
        'fund-portfolio-investments',
        payload.isFundCurrency.toString(),
        payload.investmentGroupId,
        payload.currency
      );
      yield put(
        setInvestmentPerformanceData({
          performanceData: response,
          investmentGroupId: payload.investmentGroupId,
          companyType: payload.companyType,
          isFundCurrency: payload.isFundCurrency,
          currency: payload.currency,
        })
      );
    } catch (err) {
      console.warn(err);
      yield put(errorHandlerAction({error: err}));
    }
  };

export const fundDetailsPortfolioSagaWatcher =
  function* fundDetailsPortfolioSagaWatcher() {
    yield all([
      takeEvery(
        getInvestmentPerformanceDataAction.type,
        updateFundPortfolioInvestmentsSaga
      ),
    ]);
  };
