import React from 'react';
import {ButtonSize, ButtonStyle, Func} from '../../../types';
import styles from './IconButton.module.scss';
import classnames from 'classnames';
import {Icon} from '../index';
import ButtonSpinner from '../button-spinner/ButtonSpinner';

export interface IconButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  styleType?: ButtonStyle;
  buttonSize?: ButtonSize;
  onClick: Func<[], void>;
  icon: string;
  loading?: boolean;
  className?: string;
}

const IconButton: React.FC<IconButtonProps> = ({
  id,
  styleType = ButtonStyle.Primary,
  buttonSize = ButtonSize.Medium,
  onClick,
  disabled,
  icon,
  loading = false,
  className,
}) => {
  const handleClick = (e: React.MouseEvent) => {
    e.stopPropagation();
    onClick();
  };
  return (
    <button
      id={id}
      className={classnames(
        styles.wrapper,
        styles[`wrapper__${styleType}`],
        styles[`wrapper__${buttonSize}`],
        loading && styles.loading,
        className
      )}
      onClick={handleClick}
      disabled={disabled}
      data-test={id}
    >
      <Icon name={icon} className={styles.icon} />
      {loading ? <ButtonSpinner /> : null}
    </button>
  );
};

export default IconButton;
