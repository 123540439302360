/* eslint-disable no-console */
import React from 'react';
import {t} from 'i18next';
import {SectionHeader} from '../../dashboard';
import styles from './InvestmentCommentaryDashboard.module.scss';
import {
  Button,
  GenericTable,
  genInvestmentCommentaryTableColumnDefs,
  InvestmentCommentaryTableExpandedRow,
} from '../../';
import {
  Company,
  FormStatus,
  InvestmentCommentaryItem,
  InvestmentCommentaryItemRow,
} from '../../../types';

export interface InvestmentCommentaryDashboardProps {
  title: string;
  data: Company['investmentCommentary'];
  showActionButton: boolean;
  onAddInvestmentCommentaryHandler: () => void;
  onEditInvestmentCommentary: (transaction: InvestmentCommentaryItem) => void;
  onDeleteInvestmentCommentary: (id: string) => void;
}

const investmentCommentaryPlaceholder = (
  onAddInvestmentCommentaryHandler: () => void
) => {
  return (
    <div className={styles.placeholderWrapper}>
      <p>Start building a tailored report by adding commentary</p>
      <Button
        id={`investment-commentary-placeholder-button`}
        icon="plus"
        className={styles.actionButton}
        text={t('Portfolio.Company.InvestmentCommentary.Form.Title.Add')}
        onClick={onAddInvestmentCommentaryHandler}
      />
    </div>
  );
};

const InvestmentCommentaryDashboard: React.FC<
  InvestmentCommentaryDashboardProps
> = ({
  title,
  showActionButton,
  data,
  onEditInvestmentCommentary,
  onDeleteInvestmentCommentary,
  onAddInvestmentCommentaryHandler,
}) => {
  return (
    <div className={styles.wrapper}>
      <SectionHeader
        label={title}
        labelType={'large'}
        onClick={() => null}
        withActionButton={false}
        className={styles.sectionHeader}
        childrenRight={[
          <div className={styles.buttonsWrapper}>
            {showActionButton && (
              <Button
                id={`investment-commentary-button`}
                icon="plus"
                className={styles.actionButton}
                text={t(
                  'Portfolio.Company.InvestmentCommentary.Form.Title.Add'
                )}
                onClick={onAddInvestmentCommentaryHandler}
              />
            )}
          </div>,
        ]}
      />
      <div className={styles.content}>
        {data?.data?.length > 0 ? (
          <GenericTable<InvestmentCommentaryItemRow>
            data={data.data}
            columns={genInvestmentCommentaryTableColumnDefs(
              data.headerMapping,
              onEditInvestmentCommentary,
              onDeleteInvestmentCommentary
            )}
            getRowCanExpand={() => true}
            expandedView={({row}) => (
              <InvestmentCommentaryTableExpandedRow
                row={row}
                onSubmitForm={() => {}}
                formStatus={FormStatus.Ready}
                isRequestPending={false}
              />
            )}
            expandedHandler={row => row.toggleExpanded()}
            paginated={true}
            pageSize={10}
          />
        ) : (
          investmentCommentaryPlaceholder(onAddInvestmentCommentaryHandler)
        )}
      </div>
    </div>
  );
};

export default InvestmentCommentaryDashboard;
