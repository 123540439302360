import {createAction} from '@reduxjs/toolkit';

export const errorHandlerAction = createAction(
  'error/handle-api-response-error',
  (payload: {error: any}) => {
    return {
      payload,
    };
  }
);

export const pageErrorHandlerAction = createAction(
  'error/handle-page-api-response-error',
  (payload: {error: any}) => {
    return {
      payload,
    };
  }
);
