import {createAction, createSlice, PayloadAction} from '@reduxjs/toolkit';
import {
  Company,
  CompanyGeneralInfoFormData,
  CompanyGeneralInfoFormState,
  FormStatus,
  InfoHeaderType,
  AcquisitionInformation,
  AcquisitionInformationFormState,
  CompanyAcquisitionInfoFormData,
  UpdateNewsPayload,
  NewsItemRow,
  OwnershipFormData,
  WidgetData,
  CommentaryPayload,
  InvestmentCommentary,
  InvestmentCommentaryItem,
  CompanyInvestmentCommentaryFormState,
  EditCommentaryPayload,
} from '../types';

import {DealTeamMember} from '../types/dealTeamForm';
import {
  companyAcquisitionInfoInitialFormState,
  companyGeneralInfoInitialFormState,
} from '../constants';
import i18n from '../i18n';

export const searchCompanyDetailsDealTeamMembersAction = createAction(
  'company-details-company/search-deal-team',
  (id: string, search: string) => {
    return {
      payload: {
        id,
        search,
      },
    };
  }
);

export const updateCompanyDetailsCompanyDealTeamMembersAction = createAction(
  'company-details-company/update-deal-team',
  (payload: {id: string | null; payload: DealTeamMember[]}) => {
    return {
      payload,
    };
  }
);

export const getCompanyGeneralInfoEditOptionsAction = createAction(
  'company-details-company/get-company-general-info-options',
  (payload: {id: string}) => {
    return {
      payload,
    };
  }
);
export const saveCompanyGeneralInfoEditAction = createAction(
  'company-details-company/save-company-general-info',
  (payload: {
    formData: CompanyGeneralInfoFormState;
    portfolioCompanyId?: string;
    portfolioCompanyGeneralInfoId?: string | null;
  }) => {
    return {
      payload,
    };
  }
);

export const getCompanyAcquisitionInformationEditOptionsAction = createAction(
  'company-details-company/get-company-acquisition-info-options',
  (payload: {id: string}) => {
    return {
      payload,
    };
  }
);

export const saveCompanyAcquisitionInformationEditAction = createAction(
  'company-details-company/save-company-acquisition-info',
  (payload: {
    formData: AcquisitionInformationFormState;
    portfolioCompanyId?: string;
    portfolioCompanyGeneralInfoId?: string | null;
  }) => {
    return {
      payload,
    };
  }
);

export const getFullyDilutedOwnershipEditOptionsAction = createAction(
  'company-details-company/get-fully-diluted-ownership-edit-options',
  (payload: {id: string}) => {
    return {
      payload,
    };
  }
);

export const updateFullyDilutedOwnershipDataAction = createAction(
  'company-details-company/update-fully-diluted-ownership-edit-options',
  (payload: {id: string | null; payload: OwnershipFormData[]}) => {
    return {
      payload,
    };
  }
);

export const updateCompanyLatestNewsAction = createAction(
  'company-details-company/update-news',
  (payload: UpdateNewsPayload) => {
    return {
      payload,
    };
  }
);

export const saveInvestmentCommentaryAction = createAction(
  'company-details-company/save-investment-commentary',
  (payload: CommentaryPayload) => {
    return {
      payload,
    };
  }
);

export const editInvestmentCommentaryAction = createAction(
  'company-details-company/edit-investment-commentary',
  (payload: EditCommentaryPayload) => {
    return {
      payload,
    };
  }
);

export const deleteInvestmentCommentaryAction = createAction(
  'company-details-company/delete-investment-commentary',
  (payload: {id: string}) => {
    return {
      payload,
    };
  }
);

interface CompanyDetailsCompanyData {
  data: Company | null;
  dealTeamSearchOptions: string[] | null;
  generalInfoFormData: CompanyGeneralInfoFormData;
  acquisitionInfoFormData: CompanyAcquisitionInfoFormData;
  ownershipControlFormData: OwnershipFormData[];
  investmentCommentaryFormData: CompanyInvestmentCommentaryFormState;
  formStatus: FormStatus;
  error: string | null;
  formError: Record<string, string> | null;
}

const initialState: CompanyDetailsCompanyData = {
  data: null,
  ownershipControlFormData: [],
  dealTeamSearchOptions: null,
  generalInfoFormData: {
    formState: companyGeneralInfoInitialFormState,
    options: null,
  },
  formError: null,
  error: null,
  formStatus: FormStatus.Ready,
  acquisitionInfoFormData: {
    formState: companyAcquisitionInfoInitialFormState,
    options: null,
  },
  investmentCommentaryFormData: {
    description: '',
    quarter: '',
    year: '',
    title: '',
    status: 'none',
  },
};

const companyDetailsCompanySlice = createSlice({
  name: 'company-details-company',
  initialState,
  reducers: {
    setCompanyDetailsCompanyData(
      state: CompanyDetailsCompanyData,
      action: PayloadAction<Company>
    ) {
      state.data = action.payload;
    },
    clearCompanyDetailsCompanyData(state: CompanyDetailsCompanyData) {
      state.data = null;
    },
    setCompanyDetailsDealTeamSearchOptions(
      state: CompanyDetailsCompanyData,
      action: PayloadAction<string[]>
    ) {
      state.dealTeamSearchOptions = action.payload;
    },
    clearCompanyDetailsDealTeamOptions(state: CompanyDetailsCompanyData) {
      state.dealTeamSearchOptions = null;
    },
    setCompanyDetailsCompanyDealTeamData(
      state: CompanyDetailsCompanyData,
      action: PayloadAction<DealTeamMember[]>
    ) {
      if (state.data) {
        state.data.ownershipControl.dealTeam.data = action.payload;
      }
    },
    setCompanyDetailsCompanyOwnershipData(
      state: CompanyDetailsCompanyData,
      action: PayloadAction<WidgetData>
    ) {
      if (state.data) {
        state.data.ownershipControl.ownershipData = action.payload;
      }
    },
    setFullyDilutedOwnershipEditOptions(
      state: CompanyDetailsCompanyData,
      action: PayloadAction<OwnershipFormData[]>
    ) {
      state.ownershipControlFormData = action.payload;
    },
    setCompanyGeneralInfoOptions(
      state: CompanyDetailsCompanyData,
      action: PayloadAction<CompanyGeneralInfoFormData | null>
    ) {
      state.generalInfoFormData = action.payload
        ? {
            formState: {
              companyName: action.payload.formState.companyName || '',
              geographicRegion: action.payload.formState.geographicRegion || '',
              sector: action.payload.formState.sector || '',
              industry: action.payload.formState.industry || '',
              headquartersCountry:
                action.payload.formState.headquartersCountry || '',
              state: action.payload.formState.state || '',
              stage: action.payload.formState.stage || '',
              companyRegistrationNumber:
                action.payload.formState.companyRegistrationNumber || '',
              reportingCurrency:
                action.payload.formState.reportingCurrency || '',
              website: action.payload.formState.website || '',
              facebook: action.payload.formState.facebook || '',
              youtube: action.payload.formState.youtube || '',
              twitter: action.payload.formState.twitter || '',
              linkedIn: action.payload.formState.linkedIn || '',
              instagram: action.payload.formState.instagram || '',
              description: action.payload.formState.description || '',
              fiscalYearEndMonth:
                action.payload.formState.fiscalYearEndMonth ||
                i18n.t('Global.December'),
              fiscalYearEndDay:
                action.payload.formState.fiscalYearEndDay || '31',
            },
            options: action.payload.options,
          }
        : {
            formState: companyGeneralInfoInitialFormState,
            options: null,
          };
    },
    setCompanyGeneralInfo(
      state: CompanyDetailsCompanyData,
      action: PayloadAction<InfoHeaderType[]>
    ) {
      if (state.data) {
        state.data.generalInformation = action.payload;
      }
    },
    setPortfolioCompanyGeneralInfoId(
      state: CompanyDetailsCompanyData,
      action: PayloadAction<string>
    ) {
      if (state.data) {
        state.data.portfolioCompanyGeneralInfoId = action.payload;
      }
    },
    setPortfolioCompanyIcon(
      state: CompanyDetailsCompanyData,
      action: PayloadAction<string | null>
    ) {
      if (state.data) {
        state.data.companyIcon = action.payload;
      }
    },
    setCompanyAcquisitionInfoOptions(
      state: CompanyDetailsCompanyData,
      action: PayloadAction<CompanyAcquisitionInfoFormData | null>
    ) {
      state.acquisitionInfoFormData = action.payload
        ? {
            formState: {
              enterpriseValue: action.payload.formState.enterpriseValue || '',
              acquisitionMultiple:
                action.payload.formState.acquisitionMultiple || '',
              fullyDilutedOwnership:
                action.payload.formState.fullyDilutedOwnership || '',
              multipleOf: action.payload.formState.multipleOf || '',
              investmentRole: action.payload.formState.investmentRole || '',
              coInvestors: action.payload.formState.coInvestors || [],
            },
            options: action.payload.options,
          }
        : {
            formState: companyAcquisitionInfoInitialFormState,
            options: null,
          };
    },
    setCompanyLatestNewsItem(
      state: CompanyDetailsCompanyData,
      action: PayloadAction<NewsItemRow>
    ) {
      if (state.data) {
        state.data.news.data = state.data.news.data.map(item => {
          if (item.id === action.payload.id) {
            return action.payload;
          }
          return item;
        });
      }
    },
    setCompanyResolvedAndFavoriteOfNewsItem(
      state: CompanyDetailsCompanyData,
      action: PayloadAction<UpdateNewsPayload>
    ) {
      if (state.data) {
        state.data.news.data = state.data.news.data.map(item => {
          if (item.id === action.payload.id) {
            const {id, ...rest} = action.payload;
            return {
              ...item,
              ...rest,
            };
          }
          return item;
        });
      }
    },
    setCompanyAcquisitionInfo(
      state: CompanyDetailsCompanyData,
      action: PayloadAction<AcquisitionInformation>
    ) {
      if (state.data) {
        state.data.acquisitionInformation = action.payload;
      }
    },
    setCompanyInvestmentCommentary(
      state: CompanyDetailsCompanyData,
      action: PayloadAction<InvestmentCommentary<InvestmentCommentaryItem>>
    ) {
      if (state.data) {
        state.data.investmentCommentary = action.payload;
      }
    },
    deleteCompanyInvestmentCommentaryRow(
      state: CompanyDetailsCompanyData,
      action: PayloadAction<{id: string}>
    ) {
      if (state.data) {
        const filteredResults = state.data.investmentCommentary.data.filter(
          item => item.id !== action.payload.id
        );
        state.data.investmentCommentary.data = filteredResults;
      }
    },
    setCompanyDetailsCompanyFormStatus(
      state: CompanyDetailsCompanyData,
      action: PayloadAction<FormStatus>
    ) {
      state.formStatus = action.payload;
    },
    setCompanyDetailsCompanyError(
      state: CompanyDetailsCompanyData,
      action: PayloadAction<string | null>
    ) {
      state.error = action.payload;
    },
    setCompanyDetailsCompanyFormError(
      state: CompanyDetailsCompanyData,
      action: PayloadAction<Record<string, string> | null>
    ) {
      state.formError = action.payload;
    },
  },
});

export const {
  setCompanyDetailsCompanyData,
  clearCompanyDetailsCompanyData,
  setCompanyDetailsDealTeamSearchOptions,
  clearCompanyDetailsDealTeamOptions,
  setCompanyDetailsCompanyDealTeamData,
  setCompanyDetailsCompanyFormError,
  setCompanyDetailsCompanyFormStatus,
  setCompanyDetailsCompanyError,
  setCompanyGeneralInfoOptions,
  setCompanyGeneralInfo,
  setPortfolioCompanyGeneralInfoId,
  setPortfolioCompanyIcon,
  setCompanyAcquisitionInfoOptions,
  setCompanyAcquisitionInfo,
  setCompanyDetailsCompanyOwnershipData,
  setFullyDilutedOwnershipEditOptions,
  setCompanyLatestNewsItem,
  setCompanyResolvedAndFavoriteOfNewsItem,
  setCompanyInvestmentCommentary,
  deleteCompanyInvestmentCommentaryRow,
} = companyDetailsCompanySlice.actions;

export default companyDetailsCompanySlice.reducer;
