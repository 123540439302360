import {t} from 'i18next';
import {Row} from '@tanstack/react-table';
import {
  FormStatus,
  Func,
  InvestmentCommentaryItem,
  InvestmentCommentaryItemRow,
} from '../../../../types';
import styles from './InvestmentCommentaryTableExpandedRow.module.scss';

export type InvestmentCommentaryTableExpandedRowProps<TData> = {
  row: Row<TData>;
  onSubmitForm: Func<[InvestmentCommentaryItem], void>;
  formStatus: FormStatus;
  isRequestPending: boolean;
};
const InvestmentCommentaryTableExpandedRow = ({
  row,
  onSubmitForm,
  formStatus,
  isRequestPending,
}: InvestmentCommentaryTableExpandedRowProps<InvestmentCommentaryItemRow>): JSX.Element => {
  return (
    <div className={styles.wrapper}>
      <div className={styles.column}>
        <div className={styles.sectionTitle}>
          {t('Portfolio.Company.InvestmentCommentary.Commentary.Title') + ':'}
        </div>
        <div className={styles.commentary}>{row.original.description}</div>
      </div>
    </div>
  );
};

export default InvestmentCommentaryTableExpandedRow;
