import {ModalHandlerReduxActions} from '../types';
import {updateUserDataVisibilityAction} from '../reducers/userProfileServiceSlice';
import {saveChartFilterConfigAction} from '../reducers/portfolioCompaniesChartDataSlice';
import {deleteInvestmentCommentaryAction} from '../reducers/companyDetailsCompanySlice';

export const modalHandlerReduxActions = {
  [ModalHandlerReduxActions.UserProfileDataVisibilitySignedOffToggle]:
    updateUserDataVisibilityAction,
  [ModalHandlerReduxActions.PortfolioCompaniesSaveFilterConfig]:
    saveChartFilterConfigAction,
  [ModalHandlerReduxActions.PortfolioCompanyDeleteCommentary]:
    deleteInvestmentCommentaryAction,
};
