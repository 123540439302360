import React, {useEffect, useRef, useState} from 'react';
import {
  ButtonStyle,
  StyleVariant,
  FullyDilutedOwnershipFormProps,
  OwnershipFormData,
  ButtonSize,
  FormStatus,
} from '../../../types';
import {
  Button,
  ButtonSpinner,
  Icon,
  IconButton,
  Input,
  MaskedNumberInput,
  Modal,
  TooltipWrapper,
} from '../../global';
import styles from './FullyDilutedOwnershipForm.module.scss';
import {useTranslation} from 'react-i18next';
import {useTooltip} from '../../../hooks';

const FullyDilutedOwnershipForm: React.FC<FullyDilutedOwnershipFormProps> = ({
  data,
  isOpen,
  onClose,
  onSubmit,
  formStatus,
  error,
}) => {
  const {t} = useTranslation();
  const maxSum = 100;

  const [formState, setFormState] = useState<OwnershipFormData[]>([]);
  const [balancingFigure, setBalancingFigure] = useState<number>(0);
  const [numberErrors, setNumberErrors] = useState<string[][]>(
    data.map(item => item.data.map(() => ''))
  );
  const [nameErrors, setNameErrors] = useState<string[][]>(
    data.map(item => item.data.map(() => ''))
  );

  const [
    investorNameTooltipValidationResults,
    setInvestorNameTooltipValidationResults,
  ] = useState<boolean[]>([]);

  const inputRefs = useRef<(HTMLInputElement | null)[]>([]);
  const {hideTooltip} = useTooltip();

  // This useEffect is responsible for handling visibility logic of the tooltip upon hovering the TooltipWrapper component
  // Tooltip will be shown if: input has more characters then its width AND input has no error
  useEffect(() => {
    // This tolerance constant if responsible for keeping the same behavior working both on Windows and Mac, because they render scroll bars differently, and on Mac it seems that el.scrollWidth is higher by 1 then on Windows
    const tolerance = 1;
    const tooltipConditionValidationResults = inputRefs.current.map(
      (el, index) => {
        const hasError = nameErrors.some(error => error[index]);
        return el
          ? el.scrollWidth - el.clientWidth > tolerance && !hasError
          : false;
      }
    );
    hideTooltip();
    setInvestorNameTooltipValidationResults(tooltipConditionValidationResults);
  }, [formState, nameErrors]);

  useEffect(() => {
    const processedData = replaceNullValues(data);
    setFormState(processedData);
    calculateAndValidate(processedData);
  }, [data]);

  const replaceNullValues = (
    data: OwnershipFormData[]
  ): OwnershipFormData[] => {
    return data.map(item => ({
      ...item,
      data: item.data.map(dataItem => ({
        ...dataItem,
        value: dataItem.value === null ? 0 : dataItem.value,
      })),
    }));
  };

  const calculateAndValidate = (data: OwnershipFormData[]) => {
    const investmentValues = data.flatMap(item =>
      item.data.map(dataItem =>
        dataItem.value === null || isNaN(dataItem.value) ? 0 : dataItem.value
      )
    );
    const sumOfValues = investmentValues.reduce((acc, value) => acc + value, 0);
    setBalancingFigure(
      sumOfValues > maxSum ? 0 : parseFloat((maxSum - sumOfValues).toFixed(2))
    );
    validateNumberFields(data, sumOfValues);
    validateInvestorNameFields(data);
  };

  const updateFormStateAndValidate = (
    updatedFormState: OwnershipFormData[]
  ) => {
    setFormState(updatedFormState);
    calculateAndValidate(updatedFormState);
  };

  const handleNumberChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    itemId: string,
    dataId: string | null
  ) => {
    const value = e.target.value;
    const parsedValue = value === '' ? NaN : parseFloat(value);
    const updatedFormState = formState.map(item =>
      item.id === itemId
        ? {
            ...item,
            data: item.data.map(dataItem =>
              dataItem.id === dataId || dataItem.temporaryId === dataId
                ? {...dataItem, value: parsedValue}
                : dataItem
            ),
          }
        : item
    );
    updateFormStateAndValidate(updatedFormState);
  };

  const handleInvestorNameChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    itemId: string,
    dataId: string | null
  ) => {
    const newValue = e.target.value;
    const updatedFormState = formState.map(item =>
      item.id === itemId
        ? {
            ...item,
            data: item.data.map(dataItem =>
              dataItem.id === dataId || dataItem.temporaryId === dataId
                ? {...dataItem, investorName: newValue}
                : dataItem
            ),
          }
        : item
    );
    updateFormStateAndValidate(updatedFormState);
  };

  const validateNumberFields = (
    formState: OwnershipFormData[],
    sumOfValues: number
  ): void => {
    const updatedErrors = formState.map(item =>
      item.data.map(dataItem => {
        if (dataItem.value === null || isNaN(dataItem.value)) {
          return 'Global.ErrorMessage.FieldCantBeEmpty';
        }
        if (dataItem.value < 0) {
          return 'Global.ErrorMessage.NegativeValueNotAllowed';
        }
        if (sumOfValues > maxSum) {
          return 'Global.ErrorMessage.TotalSurpasses';
        }
        return '';
      })
    );
    setNumberErrors(updatedErrors);
  };

  const validateInvestorNameFields = (formState: OwnershipFormData[]): void => {
    const updatedNameErrors = formState.map(item =>
      item.data.map(dataItem => {
        if (item.isEditable) {
          if (!dataItem.investorName?.trim()) {
            return 'Global.ErrorMessage.FieldCantBeEmpty';
          }
          if (!/^[a-zA-Z\s]*$/.test(dataItem.investorName)) {
            return 'Global.ErrorMessage.OnlyLettersAllowed';
          }
        }
        return '';
      })
    );
    setNameErrors(updatedNameErrors);
  };

  const handleAddNewInvestor = () => {
    const newInvestor = {
      id: null,
      temporaryId: Date.now().toString(),
      label: t('Portfolio.FullyDilutedOwnershipForm.OtherInvestor'),
      value: 0,
      investorName: '',
    };
    const updatedFormState = formState.map(item =>
      item.isEditable ? {...item, data: [...item.data, newInvestor]} : item
    );
    updateFormStateAndValidate(updatedFormState);
  };

  const handleDeleteInvestorRow = (itemId: string, dataId: string | null) => {
    const updatedFormState = formState.map(item =>
      item.id === itemId
        ? {
            ...item,
            data: item.data.filter(
              dataItem =>
                dataItem.id !== dataId && dataItem.temporaryId !== dataId
            ),
          }
        : item
    );
    updateFormStateAndValidate(updatedFormState);
  };

  const submitFullyDilutedOwnership = () => {
    const updatedFormState = formState.map(item => {
      if (item.isEditable) {
        return {
          ...item,
          data: item.data.map(({temporaryId, ...rest}) => rest),
        };
      }
      return item;
    });

    onSubmit(updatedFormState);
  };

  const isSubmitButtonDisabled =
    numberErrors.flat().some(error => !!error) ||
    nameErrors.flat().some(error => !!error);

  return (
    <Modal
      title={t('Portfolio.FullyDilutedOwnershipForm.FullyDilutedOwnership')}
      isOpen={isOpen}
      onClose={onClose}
      variant={StyleVariant.Secondary}
      footerChildren={
        data.length > 0 && (
          <div className={styles.buttons}>
            <Button
              text={t('Global.Save')}
              disabled={isSubmitButtonDisabled}
              loading={formStatus === FormStatus.Pending}
              onClick={submitFullyDilutedOwnership}
            />
            <Button
              styleType={ButtonStyle.Secondary}
              text={t('Global.Cancel')}
              onClick={onClose}
              disabled={formStatus === FormStatus.Pending}
            />
          </div>
        )
      }
    >
      {data.length > 0 && (
        <>
          {error && <div className={styles.errorMessage}>{error}</div>}
          {formState.map((item, itemId) => (
            <React.Fragment key={item.id}>
              <div className={styles.titleContainer}>
                <h2 className={styles.title}>{item.title}</h2>
                {!item.isEditable && <Icon name="percentage" />}
              </div>
              <div className={styles.itemsContainer}>
                {item.data.map((dataItem, dataId) => (
                  <div
                    className={styles.dataItem}
                    key={dataItem.temporaryId || dataId}
                  >
                    {item.isEditable ? (
                      <div className={styles.customInputWrapper}>
                        <div className={styles.iconButtonWrapper}>
                          <IconButton
                            id={`${dataId}-delete`}
                            onClick={() =>
                              handleDeleteInvestorRow(
                                item.id,
                                dataItem.temporaryId || dataItem.id
                              )
                            }
                            icon="trash-can"
                            title="Delete"
                          />
                        </div>
                        <TooltipWrapper
                          tooltipValue={dataItem.investorName || ''}
                          isTooltipVisible={
                            investorNameTooltipValidationResults[dataId]
                          }
                        >
                          <Input
                            label=""
                            errorMessage={t(nameErrors[itemId][dataId])}
                            validate={() => !nameErrors[itemId][dataId]}
                            placeholder={dataItem.label}
                            autoComplete="off"
                            variant={StyleVariant.Secondary}
                            value={dataItem.investorName || ''}
                            onChange={e =>
                              handleInvestorNameChange(
                                e,
                                item.id,
                                dataItem.temporaryId || dataItem.id
                              )
                            }
                            maskedRef={el => {
                              inputRefs.current[dataId] = el;
                            }}
                          />
                        </TooltipWrapper>
                      </div>
                    ) : (
                      <p>{dataItem.label}</p>
                    )}
                    <div className={styles.numberInputContainer}>
                      <MaskedNumberInput
                        label=""
                        onChange={e =>
                          handleNumberChange(
                            e,
                            item.id,
                            dataItem.temporaryId || dataItem.id
                          )
                        }
                        maskType="number"
                        errorMessage={t(numberErrors[itemId][dataId])}
                        validate={() => !numberErrors[itemId][dataId]}
                        initialValue={
                          dataItem.value !== null
                            ? dataItem.value.toString()
                            : '0.00'
                        }
                        disabled={dataItem.isDisabled}
                      />
                    </div>
                  </div>
                ))}
                {item.isEditable && (
                  <>
                    <div className={styles.dataItem}>
                      <p>
                        {t(
                          'Portfolio.FullyDilutedOwnershipForm.BalancingFigure'
                        )}
                      </p>
                      <div className={styles.numberInputContainer}>
                        <MaskedNumberInput
                          label=""
                          maskType="number"
                          errorMessage=""
                          validate={() => true}
                          initialValue={balancingFigure.toString()}
                          disabled={true}
                          value={balancingFigure.toString()}
                        />
                      </div>
                    </div>
                    <div className={styles.dataItem}>
                      <Button
                        styleType={ButtonStyle.Secondary}
                        text={t(
                          'Portfolio.FullyDilutedOwnershipForm.AddInvestor'
                        )}
                        icon={'plus'}
                        onClick={handleAddNewInvestor}
                        size={ButtonSize.Small}
                      />
                    </div>
                  </>
                )}
              </div>
            </React.Fragment>
          ))}
          <div className={styles.totalRow}>
            <h3>{t('Global.Total')}</h3>
            <p>{maxSum}%</p>
          </div>
        </>
      )}

      {!data.length && (
        <div className={styles.spinnerWrapper}>
          <ButtonSpinner className={styles.spinner} />
        </div>
      )}
    </Modal>
  );
};

export default FullyDilutedOwnershipForm;
