import React, {useMemo} from 'react';
import classnames from 'classnames';
import {useTranslation} from 'react-i18next';
import {
  ButtonStyle,
  FormStatus,
  StyleVariant,
  CompanyInvestmentCommentaryFormComponentProps,
  CompanyInvestmentCommentaryFormState,
} from '../../../types';
import {useFormState, useIsSubmitDisabled} from '../../../hooks';
import {
  Button,
  ButtonSpinner,
  Input,
  Modal,
  SelectInput,
  TextArea,
} from '../../global';
import RadioInput from '../../global/radio-input/RadioInput';
import {companyInvestmentCommentaryFormSchema} from '../../../constants';
import {getQuarterOptions, getYearSelectOptions} from '../../../utils';
import styles from './CompanyInvestmentCommentaryForm.module.scss';

const CompanyInvestmentCommentaryForm: React.FC<
  CompanyInvestmentCommentaryFormComponentProps
> = ({
  options,
  onClose,
  onSubmit,
  isOpen,
  formStatus,
  initialFormState,
  error,
  formError,
  resetErrors,
}) => {
  const {t} = useTranslation();
  const {
    errors,
    handleChange,
    isFormStateChanged,
    validate,
    formState,
    setFormState,
  } = useFormState<CompanyInvestmentCommentaryFormState>(
    initialFormState,
    companyInvestmentCommentaryFormSchema
  );

  const {isSubmitDisabled} = useIsSubmitDisabled(
    formError,
    errors,
    error,
    isFormStateChanged
  );

  const handleOnChange = (
    e: React.ChangeEvent<
      HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement
    >
  ) => {
    if (error || formError) {
      resetErrors();
    }
    handleChange(e);
  };

  const handleStatusChange = (option: {label: string; value: string}) => {
    if (error || formError) {
      resetErrors();
    }

    setFormState(prev => ({
      ...prev,
      status: option.value,
    }));
  };

  const handleSubmit = () => {
    if (validate(formState)) {
      onSubmit(formState);
    }
  };

  const handleOnClose = () => {
    if (error || formError) {
      resetErrors();
    }
    onClose();
  };

  const isTimePeriodSelected = useMemo(() => {
    return !!formState.year && !!formState.quarter;
  }, [formState.quarter, formState.year]);

  return (
    <Modal
      title={
        formState.id
          ? t('Portfolio.Company.InvestmentCommentary.Form.Title.Edit')
          : t('Portfolio.Company.InvestmentCommentary.Form.Title.Add')
      }
      isOpen={isOpen}
      onClose={handleOnClose}
      variant={StyleVariant.Secondary}
      className={classnames(styles.wrapper, !options && styles.loading)}
      footerChildren={
        options && (
          <div className={styles.buttons}>
            <Button
              text={formState.id ? t('Global.Update') : t('Global.Save')}
              onClick={handleSubmit}
              loading={formStatus === FormStatus.Pending}
              disabled={!isTimePeriodSelected || isSubmitDisabled}
            />
            <Button
              styleType={ButtonStyle.Secondary}
              text={t('Global.Cancel')}
              onClick={handleOnClose}
              disabled={formStatus === FormStatus.Pending}
            />
          </div>
        )
      }
    >
      {error ? <div className={styles.errorMessage}>{error}</div> : null}
      {options ? (
        <div className={styles.content}>
          <div className={styles.group}>
            <Input
              variant={StyleVariant.Secondary}
              label={t('Portfolio.Company.InvestmentCommentary.Title.Title')}
              name={'title'}
              placeholder={t(
                'Portfolio.Company.InvestmentCommentary.Title.Title'
              )}
              errorMessage={t(errors.title.message || formError?.title || '')}
              validate={validate}
              value={formState.title}
              onChange={handleOnChange}
            />
            {!formState.id ? (
              <p className={styles.inputHelper}>
                {t('Portfolio.Company.InvestmentCommentary.Title.Helper')}
              </p>
            ) : null}
          </div>
          <div className={styles.group}>
            <TextArea
              variant={StyleVariant.Secondary}
              name={'description'}
              label={t(
                'Portfolio.Company.InvestmentCommentary.Commentary.Title'
              )}
              placeholder={t(
                'Portfolio.Company.InvestmentCommentary.Commentary.Title'
              )}
              errorMessage={t(
                errors.description.message || formError?.description || ''
              )}
              validate={validate}
              value={formState.description}
              onChange={handleOnChange}
            />
            {!formState.id ? (
              <p className={styles.inputHelper}>
                {t('Portfolio.Company.InvestmentCommentary.Commentary.Helper')}
              </p>
            ) : null}
          </div>
          <div className={styles.group}>
            <p className={styles.groupTitle}>
              {t('Portfolio.Company.InvestmentCommentary.Time.Title')}
            </p>
            <div className={styles.dateInputs}>
              <SelectInput
                name="quarter"
                variant={StyleVariant.Secondary}
                placeholder={t('Global.Quarter')}
                options={getQuarterOptions()}
                onChange={handleOnChange}
                value={formState.quarter}
                errorMessage={t(formError?.quarter || '')}
              />
              <SelectInput
                name="year"
                variant={StyleVariant.Secondary}
                placeholder={t('Global.Year')}
                options={getYearSelectOptions(10)}
                onChange={handleOnChange}
                value={formState.year}
                errorMessage={t(formError?.year || '')}
              />
            </div>
            {!formState.id ? (
              <p className={styles.inputHelper}>
                {t('Portfolio.Company.InvestmentCommentary.Time.Description')}
              </p>
            ) : null}
          </div>
          <div className={styles.group}>
            <p className={styles.groupTitle}>
              {t('Portfolio.Company.InvestmentCommentary.Status.Title')}
            </p>
            <div className={styles.radioButtons}>
              {options.status.map(option => (
                <RadioInput
                  option={option}
                  checked={formState.status === option.value}
                  handleChange={handleStatusChange}
                  key={option.value}
                />
              ))}
            </div>
          </div>
        </div>
      ) : (
        <div className={styles.spinnerWrapper}>
          <ButtonSpinner className={styles.spinner} />
        </div>
      )}
    </Modal>
  );
};

export default CompanyInvestmentCommentaryForm;
