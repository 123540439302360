import {put, takeEvery} from 'redux-saga/effects';
import {pageErrorHandlerAction} from '../../actions';
import {AxiosError} from 'axios';
import {replace} from 'connected-react-router';
import routePaths from '../../routePaths';

export const pageErrorHandlerSaga = function* pageErrorHandlerSaga({
  payload,
}: any): any {
  const error = payload.error as AxiosError;
  if (error?.status === 404 || error?.status === 401) {
    return yield put(replace(routePaths.NOT_FOUND));
  }
  if (error?.status === 500) {
    return yield put(replace(routePaths.SERVER_ERROR));
  }
};

export const pageErrorHandlerSagaWatcher =
  function* pageErrorHandlerSagaWatcher() {
    yield takeEvery(pageErrorHandlerAction.type, pageErrorHandlerSaga);
  };
