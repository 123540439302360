import styles from './Custom-error.module.scss';
import {Link} from 'react-router-dom';
import routePaths from '../../../routePaths';
import {Button, Cog} from '../';
import {ButtonSize} from '../../../types';
import Magnifier from './Magnifier';

type CustomErrorProps = {
  title: string;
  message: string;
  errorVariant?: 'not-found' | 'generic';
  children?: React.ReactNode;
};

const ReturnHome = () => (
  <Link to={`${routePaths.HOME}`}>
    <Button
      text="Return home"
      size={ButtonSize.Large}
      icon="arrow-left"
    ></Button>
  </Link>
);
const CustomError = ({
  title,
  message,
  errorVariant = 'not-found',
  children = <ReturnHome />,
}: Readonly<CustomErrorProps>) => {
  const Icon = errorVariant === 'not-found' ? Magnifier : Cog;

  return (
    <section className={styles.wrapper}>
      <Icon aria-hidden="true" />
      <div className={styles.content}>
        <h1 className={styles.title}>{title}</h1>
        <div className={styles.info}>
          <p className={styles.message}>{message}</p>
          {children}
        </div>
      </div>
    </section>
  );
};

export default CustomError;
