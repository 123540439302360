import React from 'react';
import PageLayout from '../../layouts/page-layout/PageLayout';
import {CustomError} from '../../ui-components';
import {useTranslation} from 'react-i18next';

const NotFoundScreen: React.FC = () => {
  const {t} = useTranslation();

  return (
    <PageLayout>
      <CustomError
        title={t('Global.NotFoundView.Title')}
        message={t('Global.NotFoundView.Message')}
      />
    </PageLayout>
  );
};

export default NotFoundScreen;
