import axios from 'axios';
import {
  Company,
  CompanyGeneralInfoFormData,
  CompanyGeneralInfoFormState,
  AcquisitionInformationFormState,
  CompanyAcquisitionInfoFormData,
  OwnershipFormData,
  CommentaryPayload,
  InvestmentCommentaryResponse,
  EditCommentaryPayload,
} from '../types';
import config from '../config';
import {DealTeamMember} from '../types/dealTeamForm';

export const getCompanyDetailsCompanyOverview = async (
  token: string,
  id: string
) => {
  const {data} = await axios.get<Company>(
    `${config.APP_URI}/portfolio-company-company-overviews/${id}`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: 'application/json',
      },
    }
  );
  return data;
};

export const searchDealTeamMembers = async (
  token: string,
  id: string,
  search: string
) => {
  const {data} = await axios.get<string[]>(
    `${config.APP_URI}/portfolio-company-company-overviews/search-deal-team-member?name=${search}&portfolio_company=${id}`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: 'application/json',
      },
    }
  );
  return data;
};

export const updateDealTeamMembers = async (
  token: string,
  id: string,
  payload: DealTeamMember[]
) => {
  const {data} = await axios.post<string[]>(
    `${config.APP_URI}/portfolio-company-company-overviews/update-deal-team-members`,
    {portfolioCompany: id, dealTeamMembers: payload},
    {
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: 'application/json',
      },
    }
  );
  return data;
};

export const getCompanyGeneralInfoOptions = async (
  token: string,
  portfolioCompanyId: string
) => {
  const {data} = await axios.get<CompanyGeneralInfoFormData[]>(
    `${config.APP_URI}/portfolio-company-company-overviews/general-info-options?portfolioCompanyId=${portfolioCompanyId}`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: 'application/json',
      },
    }
  );
  return data[0];
};

export const saveCompanyGeneralInfo = async (
  token: string,
  payload: {
    formData: CompanyGeneralInfoFormState;
    portfolioCompanyId?: string;
    portfolioCompanyGeneralInfoId?: string;
  }
) => {
  if (!payload.portfolioCompanyGeneralInfoId) {
    const {data} = await axios.post<CompanyGeneralInfoFormData[]>(
      `${config.APP_URI}/portfolio-company-company-overviews/create-general-info`,
      {...payload.formData, portfolioCompany: payload.portfolioCompanyId},
      {
        headers: {
          Authorization: `Bearer ${token}`,
          Accept: 'application/json',
        },
      }
    );
    return data;
  }

  const {data} = await axios.put<CompanyGeneralInfoFormData[]>(
    `${config.APP_URI}/portfolio-company-company-overviews/update-general-info/${payload.portfolioCompanyGeneralInfoId}`,
    payload.formData,
    {
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: 'application/json',
      },
    }
  );
  return data;
};

export const getAcquisitionInformationOptions = async (
  token: string,
  portfolioCompanyId: string
) => {
  const {data} = await axios.get<CompanyAcquisitionInfoFormData[]>(
    `${config.APP_URI}/portfolio-company-company-overviews/acquisition-info-options?portfolioCompanyId=${portfolioCompanyId}`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: 'application/json',
      },
    }
  );
  return data[0];
};

export const saveAcquisitionInformation = async (
  token: string,
  payload: {
    formData: AcquisitionInformationFormState;
    portfolioCompanyId?: string;
    portfolioCompanyGeneralInfoId?: string;
  }
) => {
  if (!payload.portfolioCompanyGeneralInfoId) {
    const {data} = await axios.post<CompanyAcquisitionInfoFormData[]>(
      `${config.APP_URI}/portfolio-company-company-overviews/create-acquisition-info`,
      {...payload.formData, portfolioCompany: payload.portfolioCompanyId},
      {
        headers: {
          Authorization: `Bearer ${token}`,
          Accept: 'application/json',
        },
      }
    );
    return data;
  }

  const {data} = await axios.put<CompanyAcquisitionInfoFormData[]>(
    `${config.APP_URI}/portfolio-company-company-overviews/update-acquisition-info/${payload.portfolioCompanyGeneralInfoId}`,
    payload.formData,
    {
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: 'application/json',
      },
    }
  );
  return data;
};

export const getFullyDilutedOwnershipFormOptions = async (
  token: string,
  portfolioCompanyId: string
) => {
  const {data} = await axios.get<OwnershipFormData[]>(
    `${config.APP_URI}/portfolio-company-company-overviews/${portfolioCompanyId}/ownership`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: 'application/json',
      },
    }
  );
  return data;
};

export const updateFullyDilutedOwnershipData = async (
  token: string,
  id: string,
  payload: OwnershipFormData[]
) => {
  const {data} = await axios.put<string[]>(
    `${config.APP_URI}/portfolio-company-company-overviews/${id}/ownership`,
    payload,
    {
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: 'application/json',
      },
    }
  );
  return data;
};

export const updateCompanyLatestNews = async (
  token: string,
  id: string,
  payload: {
    resolved?: boolean;
    favorite?: boolean;
  }
) => {
  const {data} = await axios.put(`${config.APP_URI}/news/${id}`, payload, {
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: 'application/json',
    },
  });
  return data;
};

export const saveInvestmentCommentary = async (
  token: string,
  payload: CommentaryPayload
) => {
  const {data} = await axios.post<InvestmentCommentaryResponse>(
    `${config.APP_URI}/portfolio-company-company-overviews/commentaries`,
    {...payload},
    {
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: 'application/json',
      },
    }
  );
  return data;
};

export const editInvestmentCommentary = async (
  token: string,
  payload: EditCommentaryPayload
) => {
  const {id, ...rest} = payload;
  const {data} = await axios.put<InvestmentCommentaryResponse>(
    `${config.APP_URI}/portfolio-company-company-overviews/commentaries/${id}`,
    {...rest},
    {
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: 'application/json',
      },
    }
  );
  return data;
};

export const deleteInvestmentCommentary = async (
  token: string,
  payload: {id: string}
) => {
  const {id} = payload;
  const {data} = await axios.delete(
    `${config.APP_URI}/portfolio-company-company-overviews/commentaries/${id}`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: 'application/json',
      },
    }
  );
  return data;
};
