import {SortingState} from '@tanstack/react-table';
import {
  CompanyInvestmentCommentaryEditOptions,
  InvestmentCommentaryStatus,
} from '../types';

export const aiNewsTableDefaultSorting: SortingState = [
  {
    desc: true,
    id: 'date',
  },
];

export const companyInvestmentCommentaryEditOptions: CompanyInvestmentCommentaryEditOptions =
  {
    status: [
      {
        label: 'None',
        value: InvestmentCommentaryStatus.None,
      },
      {
        label: 'Above expectation',
        value: InvestmentCommentaryStatus.AboveExpectation,
      },
      {
        label: 'On track of expectation',
        value: InvestmentCommentaryStatus.OnTrack,
      },
      {
        label: 'Below expectation',
        value: InvestmentCommentaryStatus.BelowExpectation,
      },
    ],
  };
