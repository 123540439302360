import {ColumnDef, createColumnHelper} from '@tanstack/react-table';
import {getQuarter, getYear, parseISO} from 'date-fns';
import {
  FlexHAlign,
  HeaderMapping,
  MaskedDateInputPattern,
} from '../../../../types';
import {
  InvestmentCommentaryItem,
  InvestmentCommentaryItemRow,
} from '../../../../types/company';
import Th from '../cells/th/Th';
import Td from '../cells/td/Td';
import ExpanderCell from '../cells/expander-cell/ExpanderCell';
import StatusCell from '../cells/status-cell/StatusCell';
import IconButton from '../../icon-button/IconButton';
import TextTruncation from '../../text-truncation/TextTruncation';
import {formatWithLocale} from '../../../../utils';
import styles from './InvestmentCommentaryColumnDefs.module.scss';

const genInvestmentCommentaryTableColumnDefs = (
  headerMapping: HeaderMapping[],
  handleEdit: (transaction: InvestmentCommentaryItem) => void,
  handleDelete: (id: string) => void
): ColumnDef<InvestmentCommentaryItemRow, any>[] => {
  const columnHelper = createColumnHelper<InvestmentCommentaryItemRow>();

  return [
    columnHelper.accessor('title', {
      id: 'title',
      header: props => (
        <Th {...props} headerMapping={headerMapping} align={FlexHAlign.start} />
      ),
      cell: props => {
        return (
          <Td {...props} align={FlexHAlign.start} canHide={false}>
            <TextTruncation
              className={styles.textTruncation}
              text={props.row.original.title}
            />
          </Td>
        );
      },
    }),
    columnHelper.accessor('dateCreated', {
      id: 'dateCreated',
      header: props => (
        <Th {...props} headerMapping={headerMapping} align={FlexHAlign.start} />
      ),
      cell: props => {
        const date = props.getValue();
        const quarter = `Q${getQuarter(parseISO(date))}`;
        const year = getYear(parseISO(date)).toString();
        return (
          <Td
            className={styles.cell}
            {...props}
            align={FlexHAlign.start}
            emptyValue={'-'}
            canHide={false}
          >
            {`${quarter}'${year}`}
          </Td>
        );
      },
    }),
    columnHelper.accessor('lastUpdatedBy', {
      id: 'lastUpdatedBy',
      header: props => (
        <Th {...props} headerMapping={headerMapping} align={FlexHAlign.start} />
      ),
      cell: props => (
        <Td
          className={styles.cell}
          {...props}
          align={FlexHAlign.start}
          emptyValue={'-'}
          canHide={false}
        />
      ),
    }),
    columnHelper.accessor('dateLastUpdated', {
      id: 'dateLastUpdated',
      header: props => (
        <Th {...props} headerMapping={headerMapping} align={FlexHAlign.start} />
      ),
      cell: props => {
        const parsedDate = formatWithLocale(
          new Date(props.getValue()),
          MaskedDateInputPattern.dayMonthYearDashBetween
        );
        return (
          <Td
            className={styles.cell}
            {...props}
            align={FlexHAlign.start}
            emptyValue={'-'}
            canHide={false}
          >
            <>{parsedDate}</>
          </Td>
        );
      },
    }),
    columnHelper.accessor('status', {
      id: 'status',
      header: props => (
        <Th
          {...props}
          headerMapping={headerMapping}
          align={FlexHAlign.start}
          width={'200px'}
        />
      ),
      cell: props => {
        return (
          <Td {...props} align={FlexHAlign.start} canHide={false}>
            <StatusCell className={styles.cell} status={props.getValue()} />
          </Td>
        );
      },
    }),
    columnHelper.display({
      id: 'edit',
      header: props => (
        <Th
          {...props}
          align={FlexHAlign.center}
          width={'50px'}
          headerMapping={headerMapping}
        />
      ),
      cell: props => (
        <Td {...props} align={FlexHAlign.start}>
          <IconButton
            className={styles.edit}
            onClick={() => handleEdit(props.row.original)}
            icon={'pencil'}
          />
        </Td>
      ),
    }),
    columnHelper.display({
      id: 'delete',
      header: props => (
        <Th
          {...props}
          align={FlexHAlign.center}
          width={'50px'}
          headerMapping={headerMapping}
        />
      ),
      cell: props => (
        <Td {...props} align={FlexHAlign.center}>
          <IconButton
            className={styles.edit}
            onClick={() => handleDelete(props.row.original.id)}
            icon={'trash-can'}
          />
        </Td>
      ),
    }),
    columnHelper.display({
      id: 'expander',
      header: props => (
        <Th
          {...props}
          align={FlexHAlign.center}
          width={'40px'}
          headerMapping={headerMapping}
        />
      ),
      cell: props => {
        const row = props.row;
        const isExpanded = row.getIsExpanded();

        return row.getCanExpand() ? (
          <Td {...props} align={FlexHAlign.center} canHide={false}>
            <ExpanderCell expanded={isExpanded} />
          </Td>
        ) : null;
      },
    }),
  ];
};

export default genInvestmentCommentaryTableColumnDefs;
