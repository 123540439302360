import {configureStore} from '@reduxjs/toolkit';
import {History} from 'history';
import createSagaMiddleware from 'redux-saga';
import {createLogger} from 'redux-logger';
import {createBrowserHistory} from 'history';
import {routerMiddleware} from 'connected-react-router';
import createRootReducer from './reducer';
import rootSaga from './saga';
import config from './config';

// initialize history
export const history = createBrowserHistory({basename: config.PUBLIC_URL});
/**
 * Creates Redux store from seed state and root reducer.
 * Then configures it by applying middleware and attaching helper methods
 *
 * @param {Object} initialState
 * @param {Object} history
 * @returns {Object}
 */
const configureAppStore = (history: History) => {
  const sagaMiddleware = createSagaMiddleware();

  const initialState = {};

  // to persist data in session storage, utilize preloadState and sessionMiddleware

  // const initialState = preloadState(['companiesChartData']);
  // const sessionMiddleware = sessionSaverMiddleware(['companiesChartData']);

  let middleware = [
    routerMiddleware(history),
    sagaMiddleware,
    // sessionMiddleware,
  ];

  // if in development and in browser log redux actions
  if (process.env.NODE_ENV === 'development' && typeof window !== 'undefined') {
    middleware = [...(middleware as any), createLogger()];
  }

  const store = configureStore({
    reducer: createRootReducer(history),
    preloadedState: initialState,
    devTools: process.env.NODE_ENV !== 'production',
    middleware: getDefaultMiddleware =>
      getDefaultMiddleware({
        serializableCheck: {
          // Ignore these action types
          ignoredActions: [
            'documents/document-explorer/upload-file',
            'error/handle-api-response-error',
            'error/handle-page-api-response-error',
          ],
        },
      }).concat(middleware),
  });

  // store helper methods
  // store.runSaga = sagaMiddleware.run;
  sagaMiddleware.run(rootSaga);
  // store.close = () => store.dispatch(END);
  return store;
};

// initialize store
const store = configureAppStore(history);

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export default store;
