import TagManager from 'react-gtm-module';
import {matchRoutes} from 'react-router-config';
import {msalInstance} from '../..';
import routes from '../../routes';

export const pageAnalyticsSaga = function* pageAnalyticsSaga({payload}: any) {
  try {
    const accountInfo = msalInstance.getAllAccounts();
    if (accountInfo.length > 0) {
      // page call to appcues
      //@ts-ignore
      window.Appcues.page();
    }

    const matchedRoutes = matchRoutes(
      routes[0].routes as any,
      payload?.location?.pathname
    ).filter(item => item.match.isExact);
    const pageSlug = matchedRoutes[0]?.route?.path;
    const title = matchedRoutes[0]?.route?.page;
    if (pageSlug) {
      TagManager.dataLayer({
        dataLayer: {
          event: 'virtualPageview',
          pagePath: pageSlug,
          pageTitle: title,
        },
        dataLayerName: 'PageDataLayer',
      });
      //@ts-ignore
      window.analytics.page(pageSlug, {
        event: 'virtualPageview',
        pageTitle: title,
      });
    }
  } catch (e) {
    console.warn(e);
  }

  yield 0;
};
