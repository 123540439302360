import {
  all,
  call,
  put,
  select,
  takeEvery,
  takeLatest,
} from 'redux-saga/effects';
import {PayloadAction} from '@reduxjs/toolkit';
import {RootState} from '../../store';
import {acquireAuthResult, msalInstance} from '../../';
import {errorHandlerAction, pageErrorHandlerAction} from '../../actions';
import {
  CapitalWidgetType,
  FundOverview,
  NavTimeSeries,
  ActiveCompanyData,
  FundPerformanceKpiGroups,
} from '../../types';
import {
  getBenchmarkingData,
  getFundCapitalData,
  getFundDetailsOverview,
  getFundExposureData,
  getFundOverviewPorfolioCompanies,
} from '../../api/getFundDetailsOverview';
import {
  clearFundBenchmarkingData,
  clearFundOverviewData,
  getFundBenchmarkingDataAction,
  getFundOverviewCapitalData,
  getFundOverviewExposureData,
  setFundBenchmarkingData,
  setFundOverviewData,
  updateFundOverviewCapitalData,
  updateFundOverviewExposureData,
  updatePortfolioCompaniesAction,
  updatePortfolioCompaniesList,
} from '../../reducers/fundDetailsOverviewSlice';

export const fundDetailsOverviewSaga = function* fundDetailsOverviewSaga({
  payload,
}: any): any {
  const id: string = payload?.match?.params?.id;
  if (id) {
    const isUserProfileUpdatePending = yield select(
      (state: RootState) => state.userProfile.isLoading
    );

    const ga120FundOverviewPage = yield select(
      (state: RootState) => state.featureFlags.data.ga120FundOverviewPage
    );

    if (ga120FundOverviewPage) {
      window.location.replace(`/fund-monitoring/funds/${id}/overview`);
    }

    if (!isUserProfileUpdatePending) {
      yield put(clearFundOverviewData());
    }

    try {
      const {accessToken} = yield call(acquireAuthResult, msalInstance);
      const response: FundOverview = yield call(
        getFundDetailsOverview,
        accessToken,
        id
      );
      yield put(setFundOverviewData(response));
    } catch (err) {
      console.warn(err);
      yield put(pageErrorHandlerAction({error: err}));
    }
  }
};

export const updatePortfolioCompaniesSaga =
  function* updatePortfolioCompaniesSaga({
    payload,
  }: PayloadAction<{
    fundId: string;
    page: number;
  }>) {
    try {
      const {accessToken} = yield call(acquireAuthResult, msalInstance);
      const {fundId, page} = payload;
      const response: ActiveCompanyData[] = yield call(
        getFundOverviewPorfolioCompanies,
        accessToken,
        {
          fundId,
          page,
        }
      );
      yield put(
        updatePortfolioCompaniesList({
          page,
          widgets: response,
        })
      );
    } catch (err) {
      console.warn(err);
      yield put(errorHandlerAction({error: err}));
    }
  };

export const updateFundCapitalDataSaga = function* updateCapitalDataSaga({
  payload,
}: PayloadAction<{fundId: string; date: string}>) {
  try {
    const {accessToken} = yield call(acquireAuthResult, msalInstance);
    const {fundId, date} = payload;
    const response: CapitalWidgetType[] = yield call(
      getFundCapitalData,
      accessToken,
      fundId,
      date
    );
    yield put(updateFundOverviewCapitalData(response));
  } catch (err) {
    console.warn(err);
    yield put(errorHandlerAction({error: err}));
  }
};

export const updateFundExposureDataSaga = function* updateCapitalDataSaga({
  payload,
}: PayloadAction<{
  fundId: string;
  date: string;
  sortValue: string;
  sortDirection: string;
}>) {
  try {
    const {accessToken} = yield call(acquireAuthResult, msalInstance);
    const {fundId, date, sortDirection, sortValue} = payload;
    const response: ActiveCompanyData[] = yield call(
      getFundExposureData,
      accessToken,
      fundId,
      date,
      sortValue,
      sortDirection
    );
    yield put(updateFundOverviewExposureData(response));
  } catch (err) {
    console.warn(err);
    yield put(errorHandlerAction({error: err}));
  }
};

export const updateBenchmarkingDataSaga = function* updateBenchmarkingDataSaga({
  payload,
}: PayloadAction<{
  fundId: string;
  kpiName: string;
  source: string;
  kpiGroupName: FundPerformanceKpiGroups;
}>) {
  try {
    const {accessToken} = yield call(acquireAuthResult, msalInstance);
    const {fundId, kpiName, source, kpiGroupName} = payload;
    const {benchmarkingTimeSeries} = yield select(
      (state: RootState) =>
        state.fundDetails.fundOverview.data?.fundPerformanceData.widgets[0][
          kpiGroupName
        ]
    );
    if (!!benchmarkingTimeSeries) {
      yield put(clearFundBenchmarkingData({kpiGroupName}));
    }
    const response: {
      data: NavTimeSeries[];
      kpiGroupName: FundPerformanceKpiGroups;
    } = yield call(getBenchmarkingData, accessToken, fundId, kpiName, source);
    yield put(
      setFundBenchmarkingData({
        data: response.data,
        kpiGroupName: response.kpiGroupName,
      })
    );
  } catch (err) {
    console.warn(err);
    yield put(errorHandlerAction({error: err}));
  }
};

export const fundDetailsOverviewSagaWatcher =
  function* fundDetailsOverviewSagaWatcher() {
    yield all([
      takeLatest(getFundOverviewExposureData.type, updateFundExposureDataSaga),
      takeLatest(getFundOverviewCapitalData.type, updateFundCapitalDataSaga),
      takeEvery(
        updatePortfolioCompaniesAction.type,
        updatePortfolioCompaniesSaga
      ),
      takeEvery(getFundBenchmarkingDataAction.type, updateBenchmarkingDataSaga),
    ]);
  };
